@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Permanent+Marker&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.team {
  position: relative;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}
.team-content {
  width: 85%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, auto));
  align-items: center;
  gap: 2rem;
  text-align: center;
  margin-top: 4rem;
}
.team-content img {
  width: 100%;
  height: 269.41px;
  /* border-radius: 15px; */
  margin-bottom: 15px;
}
.center h1 {
  color: #081828;
  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  text-align: center;
}
.box {
  padding: 16px;
  border-radius: 15px;
  transition: all 0.38s ease;
  background-color: #fff;
  border: 1px solid #eee;
}
.box h3 {
  font-size: 15px;
  font-weight: 600;
  color: #081828;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 8px;
}
.box h5 {
  font-size: 15px;
  font-weight: 600;
  color: #66cc24;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
  letter-spacing: 2px;
}
.icons i {
  display: inline-block;
  color: #888888;
  font-size: 20px;
  margin: 0 8px;
  transition: all 0.38s ease;
}
.icons i:hover {
  transform: scale(1.2);
  color: #66cc24;
  cursor: pointer;
}
.box:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

@media (max-width: 1240px) {
  .team {
    width: 100%;
    height: auto;
    padding: 90px 2%;
  }
  .center h1 {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 767px) {
  .team-content {
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  }
}
